import * as React from "react"
import Icon1 from "../assets/img/icons/design.svg"
import Icon2 from "../assets/img/icons/code-file.svg"
import Icon3 from "../assets/img/icons/login-as-user.svg"
import Icon4 from "../assets/img/icons/smartphone-approve.svg"
import Icon5 from "../assets/img/icons/maintenance.svg"
import Icon6 from "../assets/img/icons/search-in-browser.svg"
//import MyLink from "../components/link"
import Introduction from "../components/intro"
import AgencyFriendly from "../components/agencyFriendly"
import Process from "../components/process"
import GetInTouch from "../components/getInTouch"
import SubNav from "../components/subNav"
import RecentWork from "../components/recentWork"
import Artwork from "../assets/img/artwork/artwork-01.svg"
import processJson from "../json/processes/website-design-and-development"

export default function Page() {
    return (
        <div className="service-page">
            <Introduction Artwork={Artwork}>
                <h1><span>Website</span> Design &amp; Development.</h1>
                <p className="text-larger"><strong>Custom designed, hand-coded websites with full content editing capabilities.</strong></p>
                <p className="mt-12 mb-0">
                    <a href="#examples" className="btn btn-lg btn-blue">View Examples</a>
                    <a href="#get-in-touch" className="btn btn-lg btn-blue">Get in Touch</a>
                </p>
            </Introduction>

            <SubNav
                title="Website Design &amp; Development"
                nav={['Features',  'Examples', 'Process', 'Get in Touch']}
            />

            <div id="features" className="component top-gradient-darker double-padding">
                <div className="container">
                    <h3 className="text-center font-normal cl-white">We produce websites that are:</h3>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="mt-8">
                            <Icon1 className="icon-white" />
                            <h3 className="cl-primary mt-5">Custom Designed</h3>
                            <p>No templates. We look at your identity & requirements, and produce unique artwork.</p>
                        </div>
                        <div className="mt-8">
                            <Icon2 className="icon-white" />
                            <h3 className="cl-primary mt-5">Hand Coded</h3>
                            <p>No code bloat. Clean markup, minimal plugins. Optimised for performance and search engines.</p>
                        </div>
                        <div className="mt-8">
                            <Icon3 className="icon-white" />
                            <h3 className="cl-primary mt-5">User Centered</h3>
                            <p>You only have a few seconds to make an impression. Websites should be fast & easy to navigate.</p>
                        </div>
                        <div className="mt-8">
                            <Icon4 className="icon-white" />
                            <h3 className="cl-primary mt-5">Mobile Friendly</h3>
                            <p>With mobile traffic ever increasing, it's mission critical to ensure that your website can be browsed from any device.</p>
                        </div>
                        <div className="mt-8">
                            <Icon5 className="icon-white" />
                            <h3 className="cl-primary mt-5">Easily Maintainable</h3>
                            <p>No page builders. Take control of a CMS tailored to your website's content. Or let us maintain it.</p>
                        </div>
                        <div className="mt-8">
                            <Icon6 className="icon-white" />
                            <h3 className="cl-primary mt-5">Search Engine Optimised</h3>
                            <p>Search engines need to be able to easily index all the content on your website. We help them do just that.</p>
                        </div>
                    </div>

                    <p className="text-center mt-6"><a href="mailto:info@artificial.studio" className="btn btn-green btn-lg">Get a Quote</a></p>
                </div>
            </div>

            {/* We're agency friendly */}
            <AgencyFriendly />

            {/* Examples */}
            <RecentWork title="Examples" type={['website', 'ecommerce']} />

            {/* Our Process */}
            <div id="process" className="component vh-100 top-gradient-darker pb-32">
                <h2 className="text-center mb-0">Our Process<span className="symbol symbol-green"><span></span></span></h2>
                <Process data={processJson} />
            </div>

            {/* Get in Touch */}
            <GetInTouch />

        </div>
    )
}
