import React, { useContext } from "react"
import { ModalContext } from "../components/modalContext"

export default function AgencyFriendly() {
    const modalContext = useContext(ModalContext);

    return (
        <div className="component bg-purple clear-both">
            <div className="container">
                <h2 className="text-center mb-4">We're agency friendly!<span className="symbol symbol-dark"><span></span></span></h2>
                <p className="text-center text-larger text-white">
                    Agencies build relationships with us because we're <em><strong>reliable</strong></em>, <em><strong>flexible</strong></em> and <em><strong>fast</strong></em>.<br />
                    <strong>You send us artwork, and we build it.</strong>
                </p>

                <p className="text-center mt-12">
                    <button onClick={() => modalContext.openModal('contact-modal')} className="btn btn-lg btn-dark">Say Hi!</button>
                </p>
            </div>
        </div>
    )
}